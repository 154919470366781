<div class="manage-access">

    @if(user(); as user) {
        <div class="info-section">
            <div>
                <div class="info-section__nav"
                    (click)="navigateToUsersScreen()">
                    <mat-icon>chevron_left</mat-icon>
                    <span class="nav-label">Users</span>
                </div>

                <h1 class="user-name">{{user.name}}</h1>
                <div class="email">{{user.email}}</div>
                <div class="role">{{user.role}}</div>
            </div>

            <div class="disable-user-button">
                <button mat-stroked-button
                        [disabled]="isSaving()"
                        (click)="changeUserStatus()">
                    {{inactiveUser() ? 'Re-enable user' : 'Disable user'}}
                </button>
            </div>
        </div>

        <div class="content-section">

        @if(form) {
            <form class="form"
                [formGroup]="form">
                <h1 class="title">User details
                    @if(inactiveUser() && !isLoading()) {
                        <span class="inactive">Inactive</span>
                    }
                </h1>

                <div class="form__user-fields">
                    <div class="input-field-wrapper">
                        <mat-label>Name *</mat-label>
                        <mat-form-field class="w-100">
                            <input matInput
                                maxlength="50"
                                data-testid="name"
                                formControlName="name">

                            @if(form.controls['name'].errors?.['required']) {
                                <mat-error>Name is required</mat-error>
                            }
                        </mat-form-field>
                    </div>

                    <div class="input-field-wrapper">
                        <mat-label>Email *</mat-label>
                        <mat-form-field class="w-100">
                            <input matInput
                                maxlength="50"
                                data-testid="email"
                                formControlName="email">

                            @if(form.controls['email'].errors?.['required']) {
                                <mat-error>Email is required</mat-error>
                            }

                            @if(form.controls['email'].errors?.['email']) {
                                <mat-error>Please enter a valid email address</mat-error>
                            }
                        </mat-form-field>
                    </div>

                    <div class="input-field-wrapper">
                        <mat-label>Role *</mat-label>
                        <mat-form-field class="w-100">
                            <mat-select #matSelect
                                        placeholder="Please select"
                                        data-testid="roleLink"
                                        formControlName="role">
                                <mat-option *ngFor="let role of roles"
                                            [value]="role">
                                    {{role.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                </div>

                <hr class="qpx-hr-line" />

                <div class="buildings">
                    <h1 class="title">Buildings</h1>

                    <div class="input-field-wrapper">
                        <mat-form-field class="w-100">
                            <mat-icon matPrefix
                                    [ngClass]="{'disabled' : form.disabled}">search</mat-icon>

                            <input #searchEl
                                type="text"
                                name="buildingSearch"
                                spellcheck="false"
                                autocomplete="off"
                                placeholder="Search for a building"
                                matInput
                                maxlength="50"
                                [formControl]="searchCtrl">

                            <mat-icon matSuffix
                                    class="cursor-pointer"
                                    (click)="searchCtrl.setValue('')">close</mat-icon>

                        </mat-form-field>
                    </div>

                    <mat-checkbox color="primary"
                                class="squared"
                                [disableRipple]="true"
                                [attr.disabled]="inactiveUser()"
                                [formControl]="toggleCtrl"
                                (click)="onToggleAll()">
                        <span class="select-all">Select All</span>
                    </mat-checkbox>

                    <div class="buildings__items">
                        @if(isLoading()){
                            <qpx-loader></qpx-loader>
                        } @else {
                        <ng-container *ngIf="(buildings$ | async) as buildings">
                            @if(!buildings.length) {
                                <div class="empty">Search result empty</div>
                            }
                            @for(building of buildings; track building) {
                                <mat-checkbox color="primary"
                                            class="squared"
                                            [disableRipple]="true"
                                            [disabled]="inactiveUser()"
                                            [(ngModel)]="building.isGranted"
                                            [ngModelOptions]="{standalone: true}"
                                            (ngModelChange)="onBuildingModelChange()">
                                    {{building.buildingName}}
                                </mat-checkbox>
                            }
                        </ng-container>
                        }
                    </div>
                </div>
            </form>
        }

        <div class="flex justify-end save-changes">
            <button data-testid="save"
                    mat-stroked-button
                    color="accent"
                    [disabled]="isSaving() || inactiveUser()"
                    (click)="saveChanges()">
                Save changes
            </button>
        </div>

    </div>
    } @else {
        <qpx-loader></qpx-loader>
    }

</div>