<div class="profile">

    <div class="profile__details">
        <div class="profile-inner">
            <div class="flex justify-between align-center">
                <span class="title">Profile</span>
                <button mat-stroked-button
                        color="primary"
                        *hasPermission="['update_users']"
                        (click)="navigateToManageUser()">
                    <mat-icon class="qpx-icon-sm">
                        edit
                    </mat-icon>Edit
                </button>
            </div>

            <div class="initials">{{initials()}}</div>

            @if(user(); as user){
            <div class="details">
                <mat-label>Name</mat-label>
                <div>{{user.name}}</div>

                <mat-label>Email</mat-label>
                <div>{{user.email}}</div>

                <mat-label>Organisation</mat-label>
                <div>{{user.organizationName}}</div>

                <mat-label>Role</mat-label>
                <div>{{user.role}}</div>

                <mat-label>Last login</mat-label>
                <div>{{user.lastLoggedIn | date : 'd LLL yyyy, hh:mm a'}}</div>
            </div>
            }
        </div>
    </div>

    <div class="image-wrapper">
        <div class="image"></div>
    </div>
</div>