<div class="users">
    @if(isLoading()) {
        <qpx-loader></qpx-loader>
    }

    <div class="flex justify-between align-center">
        <span class="users__title">Users</span>
        <button mat-stroked-button
                color="primary"
                *hasPermission="['create_users']"
                (click)="openAddUserModal()">
            <mat-icon class="qpx-icon-sm">
                add
            </mat-icon>Add User
        </button>
    </div>

    <form [formGroup]="form">
        <mat-form-field class="w-100 no-error">
            <mat-icon matPrefix
                      [ngClass]="{'disabled' : form.disabled}">search</mat-icon>

            <input #userSearchEl
                   type="text"
                   name="userSearch"
                   spellcheck="false"
                   autocomplete="off"
                   placeholder="Search for a user"
                   matInput
                   maxlength="50"
                   [formControl]="userSearchCtrl">

            <mat-icon matSuffix
                      class="cursor-pointer"
                      (click)="userSearchCtrl.setValue('')">close</mat-icon>

        </mat-form-field>
    </form>

    <table mat-table
           [dataSource]="dataSource()">

        <ng-container matColumnDef="name">
            <th mat-header-cell
                *matHeaderCellDef> Name </th>
            <td mat-cell
                *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="organizationName">
            <th mat-header-cell
                *matHeaderCellDef> Organisation </th>
            <td mat-cell
                *matCellDef="let element"> {{element.organizationName}} </td>
        </ng-container>

        <ng-container matColumnDef="role">
            <th mat-header-cell
                *matHeaderCellDef> Role </th>
            <td mat-cell
                *matCellDef="let element"> {{element.role}} </td>
        </ng-container>

        <ng-container matColumnDef="lastLoggedIn">
            <th mat-header-cell
                *matHeaderCellDef> Last Login </th>
            <td mat-cell
                *matCellDef="let element"> {{element.lastLoggedIn | date : 'dd/LL/yy hh:mm a'}} </td>
        </ng-container>

        <ng-container matColumnDef="createdAt">
            <th mat-header-cell
                *matHeaderCellDef> Joined date </th>
            <td mat-cell
                *matCellDef="let element"> {{element.createdAt | date : 'dd/LL/yy hh:mm a'}} </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell
                *matHeaderCellDef> Status </th>
            <td mat-cell
                *matCellDef="let element">
                <div class="flex">
                    <div class="status"
                         [ngClass]="{'active': element.isActive, 'inactive':!element.isActive }">
                        <span *ngIf="element.isActive">Active</span>
                        <span *ngIf="!element.isActive">Inactive</span>
                    </div>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell
                class="text-right"
                *matHeaderCellDef> </th>
            <td mat-cell
                class="text-right"
                *matCellDef="let element">
                <div *hasPermission="['update_users', 'delete_users']">
                    <button mat-icon-button   
                            data-testid="hamburger-open"                       
                            [matMenuTriggerFor]="menu"
                            [matMenuTriggerData]="{item: element}">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row
            *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row
            *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <div class="footer">
        <mat-paginator #paginator
                       (page)="handlePageEvent($event)"
                       [length]="totalRecords()"
                       [pageSize]="usersPayload().pageSize"
                       [disabled]="!totalRecords()"
                       [showFirstLastButtons]="true"
                       [pageSizeOptions]="[8, 25]"
                       [hidePageSize]="false"
                       [pageIndex]="usersPayload().pageIndex"
                       aria-label="Select page">
        </mat-paginator>
    </div>
</div>

<mat-menu #menu="matMenu"
          xPosition="before">
    <ng-template matMenuContent
                 let-item="item">
        <button mat-menu-item
                *hasPermission="['update_users']"
                data-testid="editButton"
                (click)="navigateToManageUser(item.identifier)">
            <span>Edit</span>
        </button>
        <button mat-menu-item
                data-testid="disableButton"
                *hasPermission="['update_users']"
                (click)="changeUserStatus(item)">
            <span>{{item.isActive ? 'Disable' : 'Enable'}}</span>
        </button>
    </ng-template>
</mat-menu>