import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { HasPermissionDirective, HasRoleDirective } from '@quipex/shared/directives';
import { environment } from 'src/environments/environment';

@Component({
    standalone: true,
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss'],
    imports: [
        RouterLink,
        RouterLinkActive,
        RouterOutlet,
        HasPermissionDirective,
        HasRoleDirective
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminComponent {
    isProdEnvironment!: boolean;

    ngOnInit(): void {
        this.isProdEnvironment = environment.isProd;
    }
}
